import type { ActionContext, ActionTree } from 'vuex';
import type { IContentAsset, IContentPassage, IContentQuestion, IContentState } from '@/store/modules/content/types';
import type { RootState } from '@/store/types';
import { ApiCaller } from '@/ApiCaller';
import apiReactStudent from '@/ApiReactStudentCaller';
import { prepareContentQuestions } from '@/utils';

type ContentActionContext = ActionContext<IContentState, RootState>;
type ContentActionTree = ActionTree<IContentState, RootState>;

export const actions: ContentActionTree = {
  //Content
  async fetchContentQuestions(context: ContentActionContext, sectionId: number): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}content/question/` + sectionId
    );

    context.commit('setContentQuestions', content.data);
  },

  async react_fetchContentQuestions(context: ContentActionContext, sectionId: string): Promise<any> {
    const content = await apiReactStudent.get(`content/question/` + sectionId);
    context.commit('setContentQuestions', content.data);
  },

  async react_fetchContentQuestionExplanation(context: ContentActionContext, questionId: number): Promise<any> {
    const content = await apiReactStudent.get(`content/explanation/` + questionId);
    await context.dispatch(
      'assessmentModule/setQuestionExplanation',
      { questionId, explanation: content.data },
      { root: true }
    );
  },

  async fetchContentCoursesQuestions(context: ContentActionContext, sectionId: number): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}content/question/` + sectionId
    );

    const questions: IContentQuestion[] = content.data as IContentQuestion[];
    questions.forEach((question: IContentQuestion) => {
      question.student_answer = '';
    });

    context.commit('setContentQuestions', questions);
  },

  async unsetQuestions(context: ContentActionContext, payload: { num: number; id: number }[]): Promise<void> {
    context.commit('unsetQuestions', payload);
  },

  async unsentContentSection(context: ContentActionContext): Promise<any> {
    context.commit('unsetContentSection');
  },

  async unsentContentQuestions(context: ContentActionContext): Promise<any> {
    context.commit('unsetContentQuestions');
  },

  async fetchContentPassages(context: ContentActionContext, sectionId: number): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}content/passages/` + sectionId
    );

    context.commit('setContentPassages', content.data);
  },

  async react_fetchContentPassages(context: ContentActionContext, sectionId: string): Promise<any> {
    const content = await apiReactStudent.get(`content/passages/` + sectionId);
    context.commit('setContentPassages', content.data);
  },
  //End Content

  //Content DSAT
  async fetchContentDSATQuestions(context: ContentActionContext, sectionId: number): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}content/question/` + sectionId
    );

    const questionsResponse: IContentQuestion[] = content.data as IContentQuestion[];
    const questions = prepareContentQuestions(questionsResponse);

    context.commit('setContentQuestions', questions);
  },

  // TODO: this function is the same as above, it is here to make it easier to test
  async react_fetchContentDSATQuestions(context: ContentActionContext, sectionId: string): Promise<any> {
    const content = await apiReactStudent.get(`content/question/` + sectionId);
    const questionsResponse: IContentQuestion[] = content.data as IContentQuestion[];
    const questions = prepareContentQuestions(questionsResponse);

    context.commit('setContentQuestions', questions);
  },

  async addMoreContentQuestions(context: ContentActionContext, questionsPayload: IContentQuestion[]): Promise<any> {
    const questions = prepareContentQuestions(questionsPayload);
    context.commit('addMoreContentQuestions', questions);
  },

  async addMoreContentPassages(context: ContentActionContext, passages: IContentPassage[]): Promise<any> {
    context.commit('addMoreContentPassages', passages);
  },

  //End Content DSAT

  //Content
  async fetchContentTSIQuestions(context: ContentActionContext, sectionId: number): Promise<any> {
    const content = await ApiCaller.getInstance().get('/tsi-content/question/' + sectionId);
    context.commit('setContentQuestions', content.data);
  },

  async fetchContentTSIPassages(context: ContentActionContext, sectionId: number): Promise<any> {
    const content = await ApiCaller.getInstance().get('/tsi-content/passages/' + sectionId);
    context.commit('setContentPassages', content.data);
  },

  async replaceContent(
    context: ContentActionContext,
    payload: { questionNum: number; index: number; html: string }
  ): Promise<any> {
    context.commit('replaceContent', payload);
  },
  //End Content

  //Quiz Content
  async fetchContentQuizQuestions(context: ContentActionContext, testNumber: number): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}content/question-quiz/` + testNumber
    );
    context.commit('setContentQuestions', content.data);
  },

  async fetchContentQuizPassages(context: ContentActionContext, testNumber: number): Promise<any> {
    const content = await ApiCaller.getInstance().get(
      `${context.rootState.productPrefix}content/quiz-passages/${testNumber}`
    );
    context.commit('setContentPassages', content.data);
  },
  //End Quiz Content

  //Practice Content
  async fetchContentPracticePassages(context: ContentActionContext, sectionId: number): Promise<any> {
    const content = await ApiCaller.getInstance().get(`/practice/passages/` + sectionId);
    context.commit('setContentPassages', content.data);
  },

  async fetchContentPracticeQuestions(context: ContentActionContext, sectionId: number): Promise<any> {
    const content = await ApiCaller.getInstance().get(`/practice/question/` + sectionId);
    context.commit('setContentQuestions', content.data);
  },
  //End Practice Content

  async addAsset(context: ContentActionContext, payload: { assetKey: string; asset: IContentAsset }): Promise<any> {
    context.commit('addAsset', payload);
  },

  async removeAsset(context: ContentActionContext, assetKey: string): Promise<any> {
    context.commit('removeAsset', assetKey);
  },

  async setQuestionTimeSpent(
    context: ContentActionContext,
    timeSpent: { questionId: number; time_spent: number }
  ): Promise<void> {
    context.commit('setQuestionTimeSpent', timeSpent);
  },

  async setQuestionTimeRemain(
    context: ContentActionContext,
    timeRemain: { questionId: number; time_remain: number }
  ): Promise<void> {
    context.commit('setQuestionTimeRemain', timeRemain);
  },
};
